

export function checkDob(value) {
    console.log("Dt ",value);
    var today = new Date();
    var dt = new Date(value);
    const yearDiff = today.getFullYear() - dt.getFullYear();

    // Get the number of months in the first year.
    const firstYearMonths = dt.getMonth() + 1;

    // Get the number of months in the second year.
    const secondYearMonths = today.getMonth();

    // Calculate the total number of months between the two dates.
    const diffInMonths = yearDiff * 12 - firstYearMonths + secondYearMonths;
    console.log("Duiff mnths",diffInMonths,yearDiff,firstYearMonths,secondYearMonths);

    if (diffInMonths < 9) {
        return "Age of child should be 9 months for applying and 18+ months for admission";
    } else if (yearDiff > 6) {
        return "Age of child should be less than 6 years for admission";
    }
}

export function formatDate(date) {
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // months are zero-indexed
    const dd = String(date.getDate()).padStart(2, '0');
    const yyyy = date.getFullYear();
    return `${mm}/${dd}/${yyyy}`;
  }